<template>
  <base-list-view
    :dataSource="value"
    :headers="headers"
    isNested
    header-hidden
    hide-default-footer
    :show-actions="!readonly"
    editable
    notShowSelect
    ref="listView"
    textCustomAdd="Добавить сотрудника"
    id="knowledge-base__records"
    :show-add-in-table="!readonly"
    :default-object="KnowledgeBase.participant"
    @customHandle="hadlerClick"
    @customAdd="showDialog = true"
  >
    <template slot="item.Employee.User.Picture" slot-scope="{ item }">
      <v-avatar style="background-color: var(--v-blue-grey-lighten3)">
        <v-img
          v-if="item.Employee.User.Picture"
          :src="getMediaByExternalId(item.Employee.User.Picture)"
        ></v-img>
        <span v-else>
          {{ item.Employee | PersonInitials }}
        </span>
      </v-avatar>
    </template>
    <template slot="item.Employee" slot-scope="scope">
      <div
        v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
        @click.stop
      >
        <m-autocomplete
          v-model="scope.rowEditing.Employee"
          :items="employees"
          label="Выберите сотрудника"
          return-object
          filter="EmployeeActive"
          dict="Employees"
          :textCustomAdd="'Создать нового сотрудника'"
        ></m-autocomplete>
      </div>

      <template v-else>
        {{ scope.item.Employee | PersonShortName }}
      </template>
    </template>

    <template slot="item.Status" slot-scope="{ item }">
      <div class="knowledge-base__status-point__wrapper">
        <span
          class="knowledge-base__status-point"
          :class="'knowledge-base__status-point--' + item.Status"
        ></span>
        Пройдено <b>{{ item.Completed || 0 }}</b> из
        <b>{{ item.Total || 0 }}</b>
      </div>
    </template>
    <select-employees
      :value="value"
      :showDialog.sync="showDialog"
      dialogTitle="Выбор сотрудников, у которых нужно обновить материал"
      button-save-text="Применить"
      @input="selectEmployee"
    >
    </select-employees>
  </base-list-view>
</template>
<script>
import { mapActions } from "vuex";
import { KnowledgeBase } from "@/data/defaultObjects";
import ListEditMixin from "@/mixins/ListEditMixin";
import BaseListView from "@/layouts/BaseListView.vue";
import MAutocomplete from "@/components/base/inputs/mAutocomplete.vue";
import SelectEmployees from "@/components/organization/employees/SelectEmployees.vue";
import { CourseEmployeeStatus } from "@/data/enums";

export default {
  name: "knowledgeBase-participants",
  components: { BaseListView, MAutocomplete, SelectEmployees },
  mixins: { ListEditMixin },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Фото",
          value: "Employee.User.Picture",
          align: "center",
          sortable: false,
          notGroup: true,
          notFilter: true,
          width: "5%",
        },
        {
          text: "Ф.И.О. сотрудника",
          value: "Employee",
          dataType: "object",
          displayText: (e) => this.$options.filters.PersonShortName(e),
        },
        {
          text: "Должность",
          value: "Employee.Position",
          dataType: "object",
          displayText: (e, item) => {
            return this.$options.filters.EmployeePosition(null, e);
          },
        },
        {
          text: "Подразделение",
          value: "Employee.Place",
          dataType: "object",
          displayText: (e, item) => {
            return this.$options.filters.EmployeePlace(null, e);
          },
          customSort: "NumWithString",
          align: "center",
        },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: CourseEmployeeStatus,
          width: "20%",
        },
      ],
      KnowledgeBase,
      employees: [],
      showDialog: false,
    };
  },
  async mounted() {
    this.employees = await this.LOAD_EMPLOYEES();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    selectEmployee(items) {
      const tmp = items.map((e) => {
        const oldEmp = this.value.find((e2) => e2.Employee?.Id === e.Id);

        if (oldEmp) {
          return { ...oldEmp };
        }
        return { Employee: e };
      });
      this.$emit("input", tmp);
    },
    hadlerClick(item) {
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "knowledgeBase/CourseEdit",
        params: {
          _objectId: item.CourseId,
          employeeId: item.Employee.Id,
          isEmployee: true,
          closeDestoroy: true,
        },
        callback: () => {},
      });
    },
  },
};
</script>
<style lang="scss">
#knowledge-base__records {
  .v-row-group__header {
    background-color: unset !important;
  }
}
</style>
