<template>
  <base-popup
    title="Урок"
    :icon="UtilGetIcon('view-MaterialEdit', 'lesson')"
    v-model="dialog"
    :disabledSave="!valid"
    @save="$emit('save', dataSource)"
    :hideSave="readonly"
    :width="width"
    :height="height"
  >
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.Num"
              label="Номер"
              readonly
            ></v-text-field>
            <v-text-field
              v-model="dataSource.Name"
              label="Название"
              maxlength="200"
              placeholder="Введите название"
              autofocus
              :readonly="readonly"
              v-up-first-symbol
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-popup>
</template>

<script>
import { KnowledgeBase } from "../../data/defaultObjects";
import BasePopup from "../../layouts/BasePopup.vue";

export default {
  name: "view-LessonEdit",
  components: {
    BasePopup,
  },
  props: {
    lesson: Object,
    value: Boolean,
    readonly: Boolean,
    width: String,
    height: String,
  },
  data: function () {
    return {
      dataSource: KnowledgeBase.lesson(),
      positions: [],
      partitions: [],
      valid: false,
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        if (value)
          this.$nextTick(() => {
            this.dataSource = this._.clone(this.lesson);
          });
      },
    },
  },
};
</script>
